import React from "react"
import Hero from "../Hero/Hero"

const HeroProductLanding = ({
    title,
    description,
    primaryCta,
    secondaryCta,
    image,
    theme,
}) => {
    return (
        <Hero
            title={title}
            description={description}
            primaryCta={primaryCta}
            secondaryCta={secondaryCta}
            image={image}
            type="productLanding"
            theme={theme}
        />
    )
}

export default HeroProductLanding
