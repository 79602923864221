import React from "react";
import styles from "./ProductsLeftyRighty.module.scss";
import ProductsLeftyRightyTile from "./ProductsLeftyRightyTile";
import Container from "../layout/Container";
import Row from "../layout/Row";
import Section from "../layout/Section";

const ProductsLeftyRighty = ({items, theme}) => {

    const itemsElements = items && items.length ? items.map((item,i) => {
        return <ProductsLeftyRightyTile item={item} key={i} />
    }) : null;

    return itemsElements ? (
        <>
            <Section className={styles.products} fullLeftyRighty={true} theme={theme}>
                <Container>
                    <Row>
                        <div className={styles.products__content}>
                            {itemsElements}
                        </div>
                    </Row>
                </Container>
            </Section>
        </>
    ) : null;
};

export default ProductsLeftyRighty;