import React from "react";
import styles from "./ProductsLeftyRightyTile.module.scss";
import getColors from "../../../util/getColors";
import GatsbyImage from "gatsby-image";
import generateFluid from "../../../util/generateFluid";
import Button from "../../elements/Button";
import FadeUp from "../../effects/FadeUp";

const ProductsLeftyRightyTile = ({item}) => {
    const productColor = item.theme && item.theme[0] ? item.theme[0].color : null;
    const themeForItem = productColor ? "custom-dark" : `${item.style}-dark`;

    const themeColorsForItem = getColors(themeForItem, productColor);

    const aspectRatioImage = item.image && item.image[0] ? item.image[0].width / item.image[0].height : 1;
    const aspectRatioImageMobile = item.mobileImage && item.mobileImage[0] ? item.mobileImage[0].width / item.mobileImage[0].height : aspectRatioImage;


    const featuresElements = item.features && item.features.length ? item.features.map((feature,j) => {
        return (
            <FadeUp as="li" delay={`${j * 0.15}s`} disableDelayMobile={true} key={j}>
                {feature.icon && feature.icon.length ? <span dangerouslySetInnerHTML={{__html: feature.icon[0].contents}} /> : null}
                {feature.feature ? <p>{feature.feature}</p> : null}
            </FadeUp>
        );
    }) : null;


    return (
        <div className={styles.productsItem}>
            <FadeUp className={styles.productsItem__details} style={themeColorsForItem}>
                {featuresElements ?
                    <div className={`${styles.productsItem__features} ${styles.productsItem__featuresMobile}`}>
                        <ul>
                            {featuresElements}
                        </ul>
                    </div>
                : null}
                <div className={styles.productsItem__details__content}>
                    <div>
                        {item.logo && item.logo.length ?
                            <GatsbyImage
                                fluid={generateFluid(
                                    item.logo[0],
                                    "(min-width: 1680px) 503px, (min-width: 600px) 29vw, 100vw",
                                    "",
                                    true
                                )}
                                className={styles.productsItem__details__content__logo}
                                style={{"--aspect-ratio": `${item.logo[0].width / item.logo[0].height}`}}
                                alt={item.productName}
                            />
                        : null}
                        {item.productTitle ? <h3>{item.productTitle}</h3> : null}
                        {item.productContent ?
                            <div className={styles.productsItem__details__content__description}>
                                <span dangerouslySetInnerHTML={{__html: item.productContent}} />
                            </div>
                        : null}
                        {item.primaryCta && item.primaryCta.url ?
                            <Button url={item.primaryCta.url} target={item.primaryCta.target} external={item.primaryCta.target === "_blank"} text={item.primaryCta.text} filledStyle={true} fillStyleSecondary={true} />
                        : null}
                        {item.secondaryCta && item.secondaryCta.url ?
                            <Button url={item.secondaryCta.url} target={item.secondaryCta.target} external={item.secondaryCta.target === "_blank"} text={item.secondaryCta.text} />
                        : null}
                    </div>
                </div>
                <div className={styles.productsItem__details__imageContainer}>
                    {item.image && item.image.length ?
                        <GatsbyImage
                            fluid={item.mobileImage && item.mobileImage.length ? 
                                [
                                    generateFluid(item.mobileImage[0], "(min-width: 1680px) 700px, (min-width: 600px) 42vw, 100vw"),
                                    generateFluid(item.image[0], "(min-width: 1680px) 700px, (min-width: 600px) 42vw, 100vw", "(min-width: 1024px)")
                                ] :
                                [
                                    generateFluid(item.image[0], "(min-width: 1680px) 700px, (min-width: 600px) 42vw, 100vw")
                                ]
                            }
                            className={styles.productsItem__details__image}
                            style={{"--aspect-ratio": aspectRatioImage, "--aspect-ratio-mobile": aspectRatioImageMobile}}
                        />
                    : null}
                </div>
            </FadeUp>
            {featuresElements ?
                <div className={styles.productsItem__features} style={{"--icon-color": themeColorsForItem["--icon-color"]}}>
                    <ul>
                        {featuresElements}
                    </ul>
                </div>
            : null}
        </div>
    );
};

export default ProductsLeftyRightyTile;