import React from "react";
import SEO from "../components/seo";
import HeroProductLanding from "../components/HeroProductLanding";
import { graphql } from "gatsby"
import Introduction from "../components/Introduction";
import Cta from "../components/Cta";
import ThreeColumnInfo from "../components/ThreeColumnInfo";
import ProductsLeftyRighty from "../components/ProductsLeftyRighty";
import IntroductionAlt from "../components/IntroductionAlt";
import Solutions from "../components/Solutions";
import Resources from "../components/Resources";
import Footer from "../components/Footer";

const productLandingPage = (props) => {
    const craftData = props.data.craft.entry;

    const {title, productLandingHero, introduction, benefitsIntroduction, threeColumnInfo, ourProducts, solutions, resources, callToAction} = craftData;

    const heroData = productLandingHero && productLandingHero.length ? productLandingHero[0] : {};
    const {displayTitle, description: heroDescription, primaryCta: heroPrimaryCta, secondaryCta: heroSecondaryCta, image: heroImage} = heroData

    const introductionData = introduction && introduction.length ? introduction[0] : {};
    const {introductionTitle, introductionContent} = introductionData;

    const benefitsIntroductionData = benefitsIntroduction && benefitsIntroduction.length ? benefitsIntroduction[0] : {};
    const {benefitsIntroductionTitle, benefitsIntroductionContent} = benefitsIntroductionData;

    const threeColumnInfoData = threeColumnInfo && threeColumnInfo.length ? threeColumnInfo : null;

    const solutionsData = solutions && solutions.length ? solutions[0] : {};
    const {solutionsTitle, solutions: solutionsItems} = solutionsData;
    const solutionsContent = solutionsData && solutionsData.solutionsContent && `<p>${solutionsData.solutionsContent}</p>`;
    
    const resourcesData = resources ? resources[0] : {};
    const {resourcesTitle, resourcesContent, resourcesLink, otherLinks: resourcesOtherLinks} = resourcesData;

    const ctaData = callToAction && callToAction.length ? callToAction[0] : {};
    const {ctaTitle: ctaText, primaryLink: ctaLink1, secondaryLink: ctaLink2} = ctaData;

    return (
        <>
            <SEO title={title || ""} seomatic={craftData.seomatic} />
            <HeroProductLanding
                title={displayTitle}
                description={heroDescription}
                primaryCta={heroPrimaryCta}
                secondaryCta={heroSecondaryCta}
                image={heroImage && heroImage.length ? heroImage : null}
                theme="light"
            />
            <Introduction
                title={introductionTitle}
                content={introductionContent}
                colorsAlt={true}
                theme="light"
            />
            <IntroductionAlt
                title={benefitsIntroductionTitle}
                content={benefitsIntroductionContent}
                theme="dark"
            />
            <ThreeColumnInfo
                items={threeColumnInfoData}
                colorsAlt={true}
                theme="dark"
            />
            <ProductsLeftyRighty
                items={ourProducts}
                theme="dark"
            />
            <Introduction
                title={solutionsTitle}
                content={solutionsContent}
                colorsAlt={true}
                shortPaddingBottom={solutionsData}
                theme="light"
            />
            <Solutions
                items={solutionsItems}
                theme="light"
            />
            <Resources
                title={resourcesTitle}
                content={resourcesContent}
                link={resourcesLink}
                otherLinks={resourcesOtherLinks}
                theme="light"
            />
            <Cta text={ctaText} link1={ctaLink1} link2={ctaLink2} theme="brightscan-dark" />
            <Footer
                theme="dark"
            />
        </>
    );
}


export const pageQuery = graphql`
    query productLandingPage($id: CRAFT_QueryArgument!) {
        craft {
            entry(id:[$id]) {
                title
                seomatic(asArray:true) {
                    metaTitleContainer
                    metaTagContainer
                    metaLinkContainer
                    metaScriptContainer
                    metaJsonLdContainer
                }
                ...on CRAFT_pages_productLandingPage_Entry {
                    title
                    productLandingHero {
                        ...on CRAFT_productLandingHero_BlockType {
                            displayTitle
                            description
                            primaryCta {
                                type
                                url
                                text
                                target
                            }
                            secondaryCta {
                                type
                                url
                                text
                                target
                            }
                            image {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "productLandingHeroImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "productLandingHeroImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                        }
                    }
                    introduction {
                        ...on CRAFT_introduction_BlockType {
                            introductionTitle
                            introductionContent
                        }
                    }
                    benefitsIntroduction {
                        ...on CRAFT_benefitsIntroduction_BlockType {
                            benefitsIntroductionTitle
                            benefitsIntroductionContent
                        }
                    }
                    threeColumnInfo {
                        ...on CRAFT_threeColumnInfo_BlockType {
                            columnTitle
                            icon {
                                url
                                contents
                            }
                            columnContent
                        }
                    }
                    ourProducts {
                        ...on CRAFT_ourProducts_BlockType {
                            logo {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "ourProductsLogoImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "ourProductsLogoImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                            productTitle
                            productContent
                            primaryCta {
                                type
                                url
                                text
                                target
                            }
                            secondaryCta {
                                type
                                url
                                text
                                target
                            }
                            image {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "productLeftyRightyDesktopImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "productLeftyRightyDesktopImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                            mobileImage {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "productLeftyRightyMobileImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "productLeftyRightyMobileImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                            style
                            features {
                                ...on CRAFT_features_feature_BlockType {
                                    feature
                                    icon {
                                        url
                                        contents
                                    }
                                }
                            }
                            theme {
                                ...on CRAFT_themes_theme_Entry {
                                    color
                                }
                            }
                        }
                    }
                    solutions {
                        ...on CRAFT_solutions_BlockType {
                            solutionsTitle
                            solutionsContent
                            solutions {
                                ...on CRAFT_solutions_solution_BlockType {
                                    tag
                                    solutionTitle
                                    solutionContent
                                    solutionLink {
                                        type
                                        url
                                        text
                                        target
                                    }
                                }
                            }
                        }
                    }
                    resources {
                        ...on CRAFT_resources_BlockType {
                            resourcesTitle
                            resourcesContent
                            resourcesLink {
                                type
                                url
                                text
                                target
                            }
                            otherLinks {
                                ...on CRAFT_otherLinks_link_BlockType {
                                    linkLink {
                                        type
                                        url
                                        text
                                        target
                                    }
                                }
                            }
                        }
                    }
                    callToAction {
                        ...on CRAFT_callToAction_BlockType {
                            ctaTitle
                            primaryLink {
                                type
                                url
                                text
                                target
                            }
                            secondaryLink {
                                type
                                url
                                text
                                target
                            }
                        }
                    }
                }
            }
        }
    }

`

export default productLandingPage;